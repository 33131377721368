<template>
  <AView
    :value="value"
    :actions="actions"
    :active="term.id === activeTermId"
    :name="term.name"
    ref="term-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <GlossaryTermGeneralEditor
            ref="form"
            v-model="term"
            :permissions="value.permissions"
            @onLinkCreate="onLinkCreate"
            @onLinkRemove="onLinkRemove"
            @action="(action) => action.handler({ from: value, term })"
          ></GlossaryTermGeneralEditor>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
<script>
import { mapGetters, mapState } from "vuex";
import { TAGS_BUTTONS } from "../../../../../../components/constants/buttons/formSection.buttons";
import GlossaryTermGeneralEditor from "../../../../../../components/wad/organisms/glossary/forms/editor/GlossaryTermGeneralEditor.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GLOSSARY_PERMISSIONS } from "../../constants/permissions.gh";
import { TAGS_PERMISSIONS } from "../../constants/permissions.gh";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import { GLOSSARY_BUTTONS } from "../../constants/actions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    GlossaryTermGeneralEditor,
  },
  data() {
    return {
      GLOSSARY_PERMISSIONS,

      term: {
        searchableKeyword: {},
      },
    };
  },
  computed: {
    ...mapState("GlossaryStore", ["activeTermId", "displayedTerms"]),
    ...mapGetters("GlossaryStore", ["termById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [GLOSSARY_BUTTONS.GENERAL_EDITOR, GLOSSARY_BUTTONS.LIST],
        this.permissions
      );
    },
  },
  async created() {
    await this.$store.dispatch("GlossaryStore/GetTerm", {
      id: this.value.relation.params.termId,
    });

    this.term = this.termById(this.value.relation.params.termId);
  },
  methods: {
    getActions() {
      const foo = [
        this.value.permissions.get(TAGS_PERMISSIONS.ALLOW_CREATE_TAG)
          ? TAGS_BUTTONS.CREATE_NEW
          : undefined,
      ].filter((el) => el);
      return foo;
    },
    onTermClick(term) {
      this.$store.dispatch("GlossaryStore/SetActiveTerm", {
        from: this.value,
        term,
      });
    },
    onTagCreate() {
      this.$store.dispatch("TagsStore/openTagCreation", {
        from: this.value,
      });
    },
    onHover() {
      this.$store.commit(
        "GlossaryStore/setActiveId",
        this.value.relation.params.termId
      );
    },
    async onLinkCreate(link) {
      const newLink = await this.$store.dispatch(
        "KnowledgeBaseStore/CreateLink",
        {
          link: { link },
        }
      );

      this.term.links.push({ link: newLink });
    },
    async onLinkRemove(link) {
      this.term.links = this.term.links.filter((el) => el.link_id !== link.id);
    },
  },

  watch: {
    displayedTerms: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.term = Object.assign(
            this.term,
            this.termById(this.value.relation.params.termId)
          );
        }
      },
      deep: true,
    },
    term: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (!this.$refs.form.validate()) return;

        await this.$store.dispatch("GlossaryStore/UpdateTerm", {
          term: newVal,
        });
      },
      deep: true,
    },
  },
};
</script>