<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.name"
      :rules="nameRules"
      label="Term Name"
      :helpText="'Provide a name of the term. Please make sure that it is unique across the system'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-model="form.searchableKeyword.name"
      :rules="nameRules"
      label="Searchable Keyword"
      :helpText="'This words will be used to adjust search. In case of Terms it\'s important to keep it the same as a name of word.'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.description"
      :rules="descriptionRules"
      label="Term Description"
      :helpText="'The description of the new term'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.non_technical_description"
      label="Term Non-Technical Description"
      :helpText="'This description should help non-technical employees and peoples understand what this term means.'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.legal_description"
      label="Legal Description"
      :helpText="'This description will be used during the document generation. So it is important to ensure a description quality.'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <!-- Business Domains -->
    <SelectorBusinessDomains
      dark
      :icon="''"
      :label="'Business Domains'"
      :helpText="'Please select all business domains that relates to this company or potentially may be used.'"
      required
      multiple
      return-object
      v-model="domains"
      autoload
    >
    </SelectorBusinessDomains>

    <SelectorExperienceNotes
      dark
      :icon="''"
      :label="'Experience Records'"
      :helpText="'Please select experience records that linked to this term'"
      required
      multiple
      return-object
      v-model="notes"
      autoload
    ></SelectorExperienceNotes>

    <!-- Tags -->
    <FormSection
      underline
      right
      class="mt-0"
      :label="'Tags'"
      :icon="'mdi-tag-multiple-outline'"
      :actions="tagActions"
      :editable="!!tagActions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>

    <SelectorKnowledgeBaseTagsVue
      dark
      autoload
      label="Tags"
      helpText="This is an actual Service Status"
      return-object
      v-model="tags"
      multiple
    >
    </SelectorKnowledgeBaseTagsVue>

    <!-- Synonyms -->

    <FormSection
      underline
      right
      class="mt-0"
      :label="'Synonyms'"
      :icon="'mdi-approximately-equal'"
      :actions="actions"
      :editable="!!actions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>

    <SelectorGlossaryTerm
      dark
      autoload
      label="Synonyms"
      helpText="You can specify a set of terms that are synonyms or have close meaning to this one "
      return-object
      v-model="synonyms"
      multiple
    ></SelectorGlossaryTerm>

    <!-- Linked Terms -->
    <FormSection
      underline
      right
      class="mt-0"
      :label="'Linked Terms'"
      :icon="'mdi-book-alphabet'"
      :actions="actions"
      :editable="!!actions.length"
      @action="(action) => $emit('action', action)"
    ></FormSection>

    <SelectorGlossaryTerm
      dark
      autoload
      label="Linked Terms"
      helpText="You can specify a set of keywords that are linked with this one."
      return-object
      v-model="linked"
      multiple
    ></SelectorGlossaryTerm>

    <!-- Useful Links -->
    <FormSection
      :label="'Useful Links'"
      class="mt-0"
      :icon="'mdi-link-variant'"
      :editable="false"
      underline
      right
    ></FormSection>

    <HelpFormInputVue
      dark
      class="mt-4"
      outlined
      v-model="newLink"
      :rules="linkRules"
      label="New Link"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      required
      @onEnter="onNewLinkCreate"
    ></HelpFormInputVue>
    <LinksList
      v-if="form.links && form.links.length > 0"
      :value="form.links"
      @onLinkRemove="onLinkRemove(link)"
    ></LinksList>
  </v-form>
</template>
      
      
<script>
import SelectorKnowledgeBaseTagsVue from "../../../../atoms/common/autocompletes/SelectorKnowledgeBaseTags.vue";
import FormSection from "../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../atoms/common/inputs/HelpFormInput.vue";
import _ from "lodash";
import SelectorGlossaryTerm from "../../../../atoms/common/autocompletes/SelectorGlossaryTerm.vue";
import LinksList from "../../../knowledgeBase/links/LinksList.vue";
import { PermissionsHelper } from "../../../../../helpers/permissions.helper";
import {
  GLOSSARY_BUTTONS,
  TAGS_BUTTONS,
} from "../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import SelectorBusinessDomains from "../../../../atoms/common/autocompletes/SelectorBusinessDomains.vue";
import SelectorExperienceNotes from "../../../../atoms/common/autocompletes/SelectorExperienceNotes.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    onLinkCreate: {
      type: Function,
      default: async () => {},
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { tags, synonyms, linked, domains, notes } = this.prepareLists(
      this.value
    );

    return {
      // Form
      pValid: this.valid,
      form: this.value,

      tags,
      synonyms,
      linked,
      domains,
      notes,
      newLink: "",

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],
    };
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [GLOSSARY_BUTTONS.CREATE_NEW, GLOSSARY_BUTTONS.LIST],
        this.permissions
      );
    },
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  components: {
    HelpFormInputVue,
    SelectorKnowledgeBaseTagsVue,
    FormSection,
    SelectorGlossaryTerm,
    LinksList,
    SelectorBusinessDomains,
    SelectorExperienceNotes,
  },
  methods: {
    prepareLists(value) {
      let tags = [];
      let synonyms = [];
      let linked = [];
      let links = [];
      let domains = [];
      let notes = [];

      if (value.tags && value.tags.length) tags = value.tags.map((t) => t.tag);

      if (value.synonyms && value.synonyms.length)
        synonyms = value.synonyms.map((s) => s.term);

      if (value.linked && value.linked.length)
        linked = value.linked.map((t) => t.term);

      if (value.links && value.links.length)
        links = value.links.map((t) => t.link);

      if (value.domains && value.domains.length)
        domains = value.domains.map((t) => t.domain);

      if (value.notes && value.notes.length)
        notes = value.notes.map((t) => t.note);

      return { tags, synonyms, linked, links, domains, notes };
    },

    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onNewLinkCreate() {
      this.$emit("onLinkCreate", this.newLink);
    },
    onLinkRemove(link) {
      this.$emit("onLinkRemove", link);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { tags, synonyms, linked, domains, notes } = this.prepareLists(newVal);

        this.tags = Object.assign(this.tags, tags);
        this.synonyms = Object.assign(this.synonyms, synonyms);
        this.linked = Object.assign(this.linked, linked);
        this.domains = Object.assign(this.domains, domains);
        this.notes = Object.assign(this.notes, notes);

        this.newLink = "";

        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        console.log("FORM UPDATED");
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
    tags: {
      handler(newVal) {
        console.log("TAGS UPDATED");
        this.form.tags = newVal.map((tag, i, arr) => {
          const existed = _.find(arr, (t) => t.tag_id === tag.id);

          return existed
            ? existed
            : {
                tag,
              };
        });
      },
      deep: true,
    },
    synonyms: {
      handler(newVal) {
        this.form.synonyms = newVal.map((term, i, arr) => {
          const existed = _.find(arr, (t) => t.term_id === term.id);

          return existed
            ? existed
            : {
                term,
              };
        });
      },
      deep: true,
    },

    linked: {
      handler(newVal) {
        this.form.linked = newVal.map((term, i, arr) => {
          const existed = _.find(arr, (t) => t.term_id === term.id);

          return existed
            ? existed
            : {
                term,
              };
        });
      },
      deep: true,
    },
    domains: {
      handler(newVal) {
        console.log("domains");

        this.form.domains = newVal.map((domain, i, arr) => {
          const existed = _.find(
            arr,
            (t) => t.business_domain_id === domain.id
          );

          return existed
            ? existed
            : {
                domain,
              };
        });
      },
      deep: true,
    },

    notes: {
      handler(newVal) {
        console.log("notes");

        this.form.notes = newVal.map((note, i, arr) => {
          console.log('arr', arr)

          const existed = _.find(
            arr,
            (t) => t.experience_note_id === note.id
          );

          return existed
            ? existed
            : {
              note,
              };
        });
      },
      deep: true,
    },
  },
};
</script>
      
      
      <style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>