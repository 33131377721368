import {
    store
} from '../../../main'


export const SERVICE_BUTTONS = {
    LIST: {
        name: "Services",
        description: "Will open Services List",
        icon: "mdi-briefcase",
        color: "#23842a",
        on: "onServicesList"
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Service",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onCreate",
        handler: () => store.dispatch('ServiceStore/openServiceCreation')
    },
    SAVE: {
        name: "Save",
        description: "Will save new Service",
        icon: "mdi-check",
        color: "#23842a",
        on: "onSave",
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Settings",
        description: "Will open General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onGeneralEdit",
    },
    HEADER_EDITOR: {
        name: "Edit",
        description: "Will open Header Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onFilesEdit",
        // handler: () => store.dispatch('ServiceStore/openHeaderEditor')
    },
    OVERVIEW_EDITOR: {
        name: "Edit",
        description: "Will open Overview Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onFilesEdit",
        // handler: () => store.dispatch('ServiceStore/openOverviewEditor')
    },

    _EDITOR: {
        name: "Edit",
        description: "Will open Examples Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onFilesEdit",
        // handler: () => store.dispatch('ServiceStore/openFilesEditor')
    },

    FREE_SECTION_CREATE_NEW: {
        name: "Create",
        description: "Will create new Service Section",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onFreeSectionCreate",
    }
}


export const DELIVERABLE_BUTTONS = {
    LIST: {
        name: "Deliverables",
        description: "Will open Deliverables List",
        icon: "mdi-file-document-multiple-outline",
        color: "#23842a",
        on: "onDeliverablesList"
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Deliverable",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onCreate",
        handler: () => store.dispatch('DeliverableStore/openServiceCreation')
    },
    FILES_EDITOR_MAIN: {
        name: "Files",
        description: "Will open Examples Editor",
        icon: "mdi-file-document-multiple",
        color: "#23842a",
        on: "onFilesEdit",
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Settings",
        description: "Will open General configurations",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onGeneralEdit",
    },
    FILES_EDITOR: {
        name: "Edit",
        description: "Will open Examples Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onFilesEdit",
    },
}


export const GLOSSARY_BUTTONS = {
    LIST: {
        name: "Glossary",
        description: "Will open Glossary",
        icon: "mdi-order-alphabetical-ascending",
        color: "#23842a",
        on: "onTermList"
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Term",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onGlossaryTermCreate"
    },
    GENERAL_EDITOR_MAIN: {
        name: "Settings",
        description: "Will Term General Editor",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onGlossaryTermGeneralEdit",
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Term General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onGlossaryTermGeneralEdit",
    },
}


export const TAGS_BUTTONS = {
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Tag",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onTagCreate"
    }
}



export const CASE_STUDY_BUTTONS = {
    LIST: {
        name: "Case Studies",
        description: "Will open Case Study List",
        icon: "mdi-store-outline",
        color: "#23842a",
        on: "onCaseStudyList"
    },
    CREATE_NEW: {
        name: "Create",
        description: "Will create new Case Study",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onCaseStudyCreate"
    },
    SETTINGS_EDITOR_MAIN: {
        name: "Settings",
        description: "Will open Case Study Settings",
        icon: "mdi-cog",
        color: "#23842a",
        on: "onCaseStudySettings",
    },
    GENERAL_EDITOR_MAIN: {
        name: "Edit",
        description: "Will open Case Study Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onCaseStudyGeneral",
    },
    FILES_EDITOR_MAIN: {
        name: "Files",
        description: "Will open Files Editor",
        icon: "mdi-file-document-multiple",
        color: "#23842a",
        on: "onCaseStudyFilesEdit",
    },
    // =================== EDITORS ===================
    GENERAL_EDITOR: {
        name: "Edit",
        description: "Will open Case Study General Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onCaseStudyGeneralEdit",
    },
    FILES_EDITOR: {
        name: "Edit",
        description: "Will open Files Editor",
        icon: "mdi-pencil",
        color: "#23842a",
        on: "onCaseStudyFilesEdit",
    },
}